.cookie-box {
    font-family: Arial, Helvetica, sans-serif !important;
    border-radius: 20px;
    width: 340px;
    position: fixed;
    bottom: 5%;
    right: 3%;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
    padding: 1rem;
    background: #e0e0e0;
    z-index: 999999999;
}

.cookie-box .cookie-row {
    font-size: 13px !important;
    line-height: 1.25em;
    margin-bottom: 0.5rem;
}

.cookie-box .cookie-row .cookie-title,
.cookie-box .cookie-row .cookie-text {
    font-size: 13px !important;
    line-height: 1.25em !important;
    margin-bottom: 0.5rem !important;
}

.cookie-box .cookie-row .cookie-text {
    color: rgb(52, 70, 76);
}

.cookie-box .cookie-row h3,
.cookie-box .cookie-row .cookie-links {
    color: #2275bd;
    font-weight: normal;
}

.cookie-box .cookie-row .cookie-links a {
    color: #2275bd;
    text-decoration: none;
    font-weight: bold;
}

.cookie-box .cookie-row-buttons {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
}

.cookie-box .cookie-row-buttons .cookie-button {
    width: 50%;
}

.cookie-box .cookie-row-buttons .cookie-button button {
    padding: 10px 30px;
    border-radius: 20px;
    cursor: pointer;
    line-height: 1;
    letter-spacing: 0;
    text-align: center !important;
}

.cookie-box .cookie-row-buttons .cookie-button .button-cancel {
    color: rgb(52, 70, 76);
    border: 1px solid rgb(52, 70, 76);
    background:#e0e0e0;
}

.cookie-box .cookie-row-buttons .cookie-button .button-accept {
    color: rgb(255, 255, 255);
    background: #2275bd;
    border: 1px solid #2275bd;
    box-shadow: #2275bd 0px 10px 28px -12px;
    float: right;
}


@media (max-width: 650px) {
    .cookie-box {
        position: fixed;
        bottom: 0%;
        left: 50%;
        height: 140px;
        transform: translate(-50%, -20%);
        padding: 1rem;
        width: 90%;
    }
}